<!--
 * @Description: 我的右侧菜单
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-19 19:00:20
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 11:35:55
-->
<template>
  <div class="my_order_lf">
    <p>{{this.$t('function')}}</p>
    <dl class="list_dl">
      <div v-for="(v,i) in menu"
           :key="i">
        <dt :class="[{'on':(v.url==curPath||v.childUrl==curPath)}]"
            v-if="!v.childs"
            @click="goPage(v)"><a>{{v.label}}</a>
        </dt>

        <dt class="list_dt"
            id="open"
            v-if="v.childs"
            @click="openMenu">
          <p>{{v.label}}</p><i></i>
        </dt>
        <dd class="list_dd"
            v-if="v.childs"
            v-show="down"
            style="display:block;">
          <ul>
            <li :class="[{'on':m.url===curPath}]"
                @click="goPage(m)"
                v-for="(m,n) in v.children"
                :key="n">
              <a>{{m.label}}</a>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import { LEFTMENU } from "@/utils/constant";
export default {
  data () {
    return {
      down: true,//默认展开我的发票
      menu:LEFTMENU
    }
  },
  computed: {
    curPath () {
      return this.$route.path
    }
  },
  methods: {
    //打开对应的页面
    goPage (v) {
      this.$router.push(v.url);
    },
    openMenu () {
      this.down = !this.down
    }
  }
}
</script>

<style>
</style>