<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-19 19:30:56
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 10:39:33
-->
<template>
  <div>
    <TopMyNav></TopMyNav>
    <div class="w ww my_index">
      <div class="bread">{{this.$t('position')}} > <a href="#">个人中心</a> > <a>{{curPath}}</a></div>
      <div class="pr">
        <div class="com-left">
          <Left @setName="getLocName"></Left>
        </div>
        <div class="com-right">
          <router-view></router-view>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Left from '@/components/component/Myleft.vue';
import TopMyNav from '@/components/component/Search.vue';
import { LEFTMENU } from "@/utils/constant";
export default {
  components: {
    Left, TopMyNav
  },
  computed: {
    curPath () {
      let curPath = this.$route.path;
      let name;
      LEFTMENU.find(v => {
        if (v.url == curPath)
        {
          name = v.label;
          return
        }
        if (v.childs)
        {
          v.children.forEach((x) => {
            if (x.url == curPath)
            {
              name = x.label;
              return
            }
          })
        }
      });
      return name
    }
  },
  data () {
    return {
      locationName: '',
    }
  },
  methods: {
    getLocName (name) {
      console.log("namename", name);
      this.locationName = name
    }
  }
}
</script>

<style lang="less" scoped>
.com-left {
  width: 0.55rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f8f8f8;
  padding: 0.05rem 0.07rem 0 0.07rem;
}
.com-right {
  width: calc(100% - 0.65rem);
  margin-left: 0.65rem;
  margin-top: 0.12rem;
  min-height: 2.5rem;
}
</style>